@import url("https://fonts.googleapis.com/css?family=PT+Sans+Narrow|PT+Sans:400,700&display=swap");
nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #A5B4CC;
  font-family: "PT Sans", sans-serif;
}

.contactLinks {
  color: black!important;
  text-shadow: 1px 1px #eafcf9b7;
  /* text-decoration: underline; */
}

.contactLinks:hover {
    color: rgb(226, 149, 6) !important;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.671);
    text-decoration-color: black;
}

@media only screen and (max-width: 600px) {
  nav {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
