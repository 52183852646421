@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow|PT+Sans:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow|PT+Sans:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow|PT+Sans:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 20px;
}

a#discogs {
  -webkit-text-decoration: underline 1px rgb(226, 149, 6);
          text-decoration: underline 1px rgb(226, 149, 6);
  color: black;
}

a#discogs:hover {
  -webkit-text-decoration-color: blue;
          text-decoration-color: blue;
}

.box {
  background-color: #a5b4cc !important;
  text-align: center;
  align-items: baseline;
  color: black !important;
  font-family: "PT Sans", sans-serif;
  border-radius: 0px !important;
}

.profileImg {
  box-shadow: 1px 1px 2px black;
  border-radius: 5%;
  max-height: 486px;
  width: 400px;
}

.column {
  align-self: center;
}

.contactHead {
  font-size: 42px !important;
  margin: 14px;
}

.intro {
  margin-top: 10px;
  font-size: 54px;
  line-height: 70px;
  margin-bottom: 10px;
}

.subtitle {
  color: rgba(0, 0, 0, 0.835) !important;
}
.orange {
  text-shadow: 1px 1px rgb(226, 149, 6);
}

#small {
  font-size: 16px;
}

#emphasis {
  font-weight: 500;
  text-decoration: underline;
  -webkit-text-decoration-color: rgb(226, 149, 6);
          text-decoration-color: rgb(226, 149, 6);
}

.projectPic {
  border-bottom: 1px solid rgba(128, 128, 128, 0.322);
  height: 250px;
  width: 500px;
  margin-bottom: 0px;
  cursor: pointer;
}

.imgLink {
  height: 250px;
}

.projBox {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  box-shadow: 1px 1px 3px grey;
  background-color: #ffebe8;
  font-family: "PT Sans", sans-serif;
  padding-top: 60px;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.card {
  display: flex;
  flex-flow: column wrap;
  margin: 10px;
}

.title {
  color: black !important;
  font-weight: 500 !important;
}

.title:hover {
  color: black !important;
  text-decoration: underline;
  outline: 2px rgba(0, 0, 0, 0.774) solid;
}

.text {
  display: flex;
  flex-direction: column;
  width: 500px;
  text-align: center;
  background-color: rgba(132, 143, 165, 0.21);
  padding: 5px;
  letter-spacing: 0.45px;
  height: 100%;
}

.recent {
  text-align: center;
  margin-top: 20px;
  font-size: 48px !important;
  color: black !important;
}

.anchor {
  align-self: center;
}

.subtitle {
  font-family: "PT Sans Narrow", sans-serif;
}

.anchor {
  color: black;
}

#ghLink {
  align-self: flex-end;
  font-size: 14px;
}

.anchor:hover {
  color: rgb(34, 121, 236) !important;
}

.projectPic:hover {
  -webkit-filter: drop-shadow(5px 5px 5px #848fa58c);
          filter: drop-shadow(5px 5px 5px #848fa58c);
}

@media only screen and (max-width: 500px) {
  .text {
    width: 100vw;
  }
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #A5B4CC;
  font-family: "PT Sans", sans-serif;
}

.contactLinks {
  color: black!important;
  text-shadow: 1px 1px #eafcf9b7;
  /* text-decoration: underline; */
}

.contactLinks:hover {
    color: rgb(226, 149, 6) !important;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.671);
    -webkit-text-decoration-color: black;
            text-decoration-color: black;
}

@media only screen and (max-width: 600px) {
  nav {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

