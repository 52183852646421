@import url("https://fonts.googleapis.com/css?family=PT+Sans+Narrow|PT+Sans:400,700&display=swap");
.projectPic {
  border-bottom: 1px solid rgba(128, 128, 128, 0.322);
  height: 250px;
  width: 500px;
  margin-bottom: 0px;
  cursor: pointer;
}

.imgLink {
  height: 250px;
}

.projBox {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  box-shadow: 1px 1px 3px grey;
  background-color: #ffebe8;
  font-family: "PT Sans", sans-serif;
  padding-top: 60px;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.card {
  display: flex;
  flex-flow: column wrap;
  margin: 10px;
}

.title {
  color: black !important;
  font-weight: 500 !important;
}

.title:hover {
  color: black !important;
  text-decoration: underline;
  outline: 2px rgba(0, 0, 0, 0.774) solid;
}

.text {
  display: flex;
  flex-direction: column;
  width: 500px;
  text-align: center;
  background-color: rgba(132, 143, 165, 0.21);
  padding: 5px;
  letter-spacing: 0.45px;
  height: 100%;
}

.recent {
  text-align: center;
  margin-top: 20px;
  font-size: 48px !important;
  color: black !important;
}

.anchor {
  align-self: center;
}

.subtitle {
  font-family: "PT Sans Narrow", sans-serif;
}

.anchor {
  color: black;
}

#ghLink {
  align-self: flex-end;
  font-size: 14px;
}

.anchor:hover {
  color: rgb(34, 121, 236) !important;
}

.projectPic:hover {
  filter: drop-shadow(5px 5px 5px #848fa58c);
}

@media only screen and (max-width: 500px) {
  .text {
    width: 100vw;
  }
}
